import { DEFAULT_CONFIG } from "../../utils/FeatureConfig";
import { SET_FEATURE_CONFIG, SET_NEW_USER, UPDATE_DATA_VERSION, UPDATE_MIN_VERSION, USER_LOGGED_IN, USER_LOGGED_OUT } from "../actions/UserActionTypes";
import { updateObject } from "./AppReducer";
var userInitialState = {
    currentUser: null,
    isNewUser: false,
    minVersion: null,
    dataVersion: null,
    featureConfig: DEFAULT_CONFIG
};
export function userReducer(state, action) {
    if (state === void 0) { state = userInitialState; }
    switch (action.type) {
        case SET_NEW_USER: {
            return updateObject(state, {
                isNewUser: action.isNew
            });
        }
        case USER_LOGGED_IN:
            return updateObject(state, {
                currentUser: action.user
            });
        case USER_LOGGED_OUT:
            return updateObject(state, {
                currentUser: null
            });
        case UPDATE_MIN_VERSION:
            return updateObject(state, {
                minVersion: action.minVersion
            });
        case UPDATE_DATA_VERSION:
            return updateObject(state, {
                dataVersion: action.dataVersion
            });
        case SET_FEATURE_CONFIG:
            return updateObject(state, {
                featureConfig: action.featureConfig
            });
        default:
            return state;
    }
}
