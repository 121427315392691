export var Theme;
(function (Theme) {
    Theme["DARK"] = "dark";
    Theme["LIGHT"] = "light";
})(Theme || (Theme = {}));
export function toTheme(theme) {
    switch (theme) {
        case "light":
            return Theme.LIGHT;
        case "dark":
        default:
            return Theme.DARK;
    }
}
