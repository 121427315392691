export function noteDbDataToNoteData(noteDbData) {
    var _a;
    return {
        noteId: noteDbData.id,
        title: noteDbData.title,
        content: noteDbData.content,
        cursorPosition: noteDbData.cursorPosition,
        lastEditSession: noteDbData.lastEditSession,
        lastUpdateTime: noteDbData.lastUpdateTime,
        editMode: noteDbData.editMode,
        mime: noteDbData.mime,
        splitMode: noteDbData.splitMode,
        diagramData: (_a = noteDbData.diagramData) !== null && _a !== void 0 ? _a : undefined
    };
}
export function getContentPreview(noteData, previewTextMaxLength) {
    return noteData.content.substr(0, previewTextMaxLength);
}
