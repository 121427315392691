import { Theme } from "../../app/theme/Theme";
import { CHANGE_FILE_NAVIGATION_MODE, DISMISS_ALERT_MESSAGE, DISMISS_CONFIRM_DELETE_FILE_DIALOG, DISMISS_FORMAT_ERROR_DIALOG, DISMISS_LOGIN_DIALOG, DISMISS_LOGOUT_DIALOG, DISMISS_MESSAGE_DIALOG, DISMISS_MOVE_TO_FOLDER_DIALOG, DISMISS_NEW_FILE_DIALOG, DISMISS_RENAME_FILE_DIALOG, DISMISS_RIGHT_CLICK_MENU, DISMISS_SETTINGS_DIALOG, INITIALIZING_APP, REQUEST_FORMAT_CODE, RESIZE_NOTE_ITEM_HEIGHT, SET_DISTRACTION_FREE_VIEW, SET_EDITING_DIAGRAM, SET_EDITING_FILE, SET_EDITING_NOTE, SET_THEME, SHOW_ALERT_MESSAGE, SHOW_CONFIRM_DELETE_FILE_DIALOG, SHOW_FORMAT_ERROR_DIALOG, SHOW_LOGIN_DIALOG, SHOW_LOGOUT_DIALOG, SHOW_MESSAGE_DIALOG, SHOW_MOVE_TO_FOLDER_DIALOG, SHOW_NEW_FILE_DIALOG, SHOW_RENAME_FILE_DIALOG, SHOW_RIGHT_CLICK_MENU, SHOW_SETTINGS_DIALOG, UPDATE_NETWORK_STATUS } from "../actions/EnvironmentActionTypes.";
import { UPDATE_EXPORT_PROGRESS } from "../actions/ExportActionTypes";
import { USER_LOGGED_OUT } from "../actions/UserActionTypes";
import { updateObject } from "./AppReducer";
var initialState = {
    deleteFileDialog: null,
    renameFileDialog: null,
    newFileDialog: {
        isFolder: false,
        isShow: false,
        parentFolderId: ""
    },
    loginDialog: null,
    isShowingLogoutDialog: false,
    moveToFolderDialog: null,
    isInitializing: true,
    rightClickMenu: {
        isShowing: false,
        position: null,
        menuItems: []
    },
    currentFileNavigationMode: null,
    editingNoteId: null,
    editingDiagramId: null,
    editingFileId: null,
    isOffline: false,
    formatError: null,
    requestFormatCode: false,
    alertMessage: null,
    currentSettingsPage: null,
    isDistractionFreeView: false,
    exportProgress: null,
    theme: Theme.DARK,
    messageDialog: null,
    isResizingNoteItemHeight: false
};
export function environmentReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RESIZE_NOTE_ITEM_HEIGHT: {
            return updateObject(state, {
                isResizingNoteItemHeight: action.isResizing
            });
        }
        case SHOW_MESSAGE_DIALOG: {
            return updateObject(state, {
                messageDialog: action.message
            });
        }
        case DISMISS_MESSAGE_DIALOG: {
            return updateObject(state, {
                messageDialog: null
            });
        }
        case SET_THEME: {
            return updateObject(state, {
                theme: action.theme
            });
        }
        case UPDATE_EXPORT_PROGRESS: {
            return updateObject(state, {
                exportProgress: action.progress
            });
        }
        case SET_DISTRACTION_FREE_VIEW: {
            return updateObject(state, { isDistractionFreeView: action.isShow });
        }
        case SHOW_SETTINGS_DIALOG: {
            return updateObject(state, { currentSettingsPage: action.page });
        }
        case DISMISS_SETTINGS_DIALOG: {
            return updateObject(state, { currentSettingsPage: null });
        }
        case SHOW_ALERT_MESSAGE: {
            return updateObject(state, { alertMessage: action.message });
        }
        case DISMISS_ALERT_MESSAGE: {
            return updateObject(state, { alertMessage: null });
        }
        case REQUEST_FORMAT_CODE: {
            return updateObject(state, { requestFormatCode: action.isRequest });
        }
        case SHOW_FORMAT_ERROR_DIALOG: {
            return updateObject(state, { formatError: action.error });
        }
        case DISMISS_FORMAT_ERROR_DIALOG: {
            return updateObject(state, { formatError: null });
        }
        case UPDATE_NETWORK_STATUS: {
            return updateObject(state, { isOffline: action.isOffline });
        }
        case SET_EDITING_NOTE: {
            return updateObject(state, { editingNoteId: action.noteId });
        }
        case SET_EDITING_DIAGRAM: {
            return updateObject(state, { editingDiagramId: action.fileId });
        }
        case SET_EDITING_FILE: {
            return updateObject(state, { editingFileId: action.fileId });
        }
        case USER_LOGGED_OUT: {
            return updateObject(state, initialState);
        }
        case CHANGE_FILE_NAVIGATION_MODE: {
            return updateObject(state, {
                currentFileNavigationMode: action.mode
            });
        }
        case SHOW_NEW_FILE_DIALOG: {
            return updateObject(state, {
                newFileDialog: {
                    isShow: true,
                    isFolder: action.isFolder,
                    parentFolderId: action.parentFolderId
                }
            });
        }
        case DISMISS_NEW_FILE_DIALOG: {
            return updateObject(state, {
                newFileDialog: { isShow: false, isFolder: false, parentFolderId: "" }
            });
        }
        case SHOW_RIGHT_CLICK_MENU: {
            return updateObject(state, {
                rightClickMenu: {
                    position: action.position,
                    menuItems: action.menuItems,
                    isShowing: true
                }
            });
        }
        case DISMISS_RIGHT_CLICK_MENU: {
            return updateObject(state, {
                rightClickMenu: {
                    isShowing: false,
                    position: null,
                    menuItems: []
                }
            });
        }
        case INITIALIZING_APP: {
            return updateObject(state, {
                isInitializing: action.isInitializing
            });
        }
        case SHOW_LOGIN_DIALOG: {
            return updateObject(state, {
                loginDialog: { forceLogin: action.forceLogin },
                isShowingLogoutDialog: false
            });
        }
        case DISMISS_LOGIN_DIALOG: {
            return updateObject(state, {
                loginDialog: null
            });
        }
        case SHOW_LOGOUT_DIALOG: {
            return updateObject(state, {
                isShowingLogoutDialog: true
            });
        }
        case DISMISS_LOGOUT_DIALOG: {
            return updateObject(state, {
                isShowingLogoutDialog: false
            });
        }
        case SHOW_CONFIRM_DELETE_FILE_DIALOG: {
            return updateObject(state, {
                deleteFileDialog: {
                    fileId: action.fileId,
                    fileName: action.fileName,
                    isFolder: action.isFolder
                }
            });
        }
        case DISMISS_CONFIRM_DELETE_FILE_DIALOG: {
            return updateObject(state, {
                deleteFileDialog: null
            });
        }
        case SHOW_RENAME_FILE_DIALOG: {
            return updateObject(state, {
                renameFileDialog: {
                    fileId: action.fileId,
                    fileName: action.fileName,
                    isFolder: action.isFolder
                }
            });
        }
        case DISMISS_RENAME_FILE_DIALOG: {
            return updateObject(state, {
                renameFileDialog: null
            });
        }
        case SHOW_MOVE_TO_FOLDER_DIALOG: {
            return updateObject(state, {
                moveToFolderDialog: { targetFile: action.targetFile }
            });
        }
        case DISMISS_MOVE_TO_FOLDER_DIALOG: {
            return updateObject(state, {
                moveToFolderDialog: null
            });
        }
        default:
            return state;
    }
}
