import { DEFAULT_CONTENT_PREVIEW_ROW } from "../app/model/FileMetadata";
var ROOT_FOLDER_NAME = "Notes";
export var ROOT_FOLDER_ID = "1";
export var emptyRootFolder = {
    id: ROOT_FOLDER_ID,
    name: ROOT_FOLDER_NAME,
    toggled: true,
    children: [],
    contentPreview: "",
    contentPreviewRow: DEFAULT_CONTENT_PREVIEW_ROW,
    lastUpdateTime: new Date().getTime(),
    pinned: false,
    mime: ""
};
