import { combineReducers } from "redux";
import { environmentReducer } from "./EnvironmentReducer";
import { fileReducer } from "./FileReducer";
import { userReducer } from "./UserReducer";
export function updateObject(oldObject, values) {
    return Object.assign({}, oldObject, values);
}
var appReducer = combineReducers({
    files: fileReducer,
    user: userReducer,
    environment: environmentReducer
});
export default appReducer;
