var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import memoize from "memoize-one";
import { isFolder } from "../model/FileMetadata";
var FileSorter = /** @class */ (function () {
    function FileSorter() {
    }
    FileSorter.sortByFileName = memoize(function (files) {
        return __spreadArray([], __read(files), false).sort(function (file1, file2) {
            if (isFolder(file1) && !isFolder(file2)) {
                return -1;
            }
            if (!isFolder(file1) && isFolder(file2)) {
                return 1;
            }
            return file1.name.localeCompare(file2.name);
        });
    });
    return FileSorter;
}());
export default FileSorter;
