var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import produce from "immer";
import { CONTENT_PREVIEW_LENGTH, updateContentPreview, updateField } from "../../app/model/FileMetadata";
import { emptyRootFolder } from "../../model/RootFolderData";
import { SET_MERMAID_THEME } from "../actions/DiagramActionTypes";
import { CHANGE_EDIT_MODE, SET_MIME, SET_PIN, SET_SPLIT_MODE, UPDATE_NOTE_FOLDER } from "../actions/FileActionTypes";
import { ADD_NOTE, DELETE_NOTE, RENAME_NOTE, UPDATE_LOCAL_NOTE_CONTENT, UPDATE_LOCAL_NOTE_LAST_UPDATE_TIME } from "../actions/NoteActionTypes";
import { USER_LOGGED_OUT } from "../actions/UserActionTypes";
import { updateObject } from "./AppReducer";
var initialState = {
    notes: new Array(),
    noteFolder: emptyRootFolder
};
export function fileReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_MERMAID_THEME: {
            var updatedNotes = updateNote(state.notes, action.noteId, function (note) {
                note.diagramData = {
                    mermaidTheme: action.theme
                };
            });
            return updateObject(state, { notes: updatedNotes });
        }
        case UPDATE_LOCAL_NOTE_LAST_UPDATE_TIME: {
            return updateObject(state, {
                notes: state.notes.map(function (note) {
                    if (note.noteId === action.noteId) {
                        return updateObject(note, {
                            lastUpdateTime: action.lastUpdateTime
                        });
                    }
                    else {
                        return note;
                    }
                }),
                noteFolder: updateLastUpdateTime(state.noteFolder, action.noteId, action.lastUpdateTime)
            });
        }
        case SET_SPLIT_MODE: {
            return updateObject(state, {
                notes: state.notes.map(function (note) {
                    if (note.noteId === action.noteId) {
                        return updateObject(note, { splitMode: action.splitMode });
                    }
                    else {
                        return note;
                    }
                })
            });
        }
        case SET_PIN: {
            return updateObject(state, {
                noteFolder: setPin(state.noteFolder, action.fileId, action.isPinned)
            });
        }
        case USER_LOGGED_OUT: {
            return updateObject(state, initialState);
        }
        case SET_MIME: {
            return updateObject(state, {
                notes: state.notes.map(function (note) {
                    if (note.noteId === action.noteId) {
                        return updateObject(note, { mime: action.mime });
                    }
                    else {
                        return note;
                    }
                })
            });
        }
        case CHANGE_EDIT_MODE: {
            return updateObject(state, {
                notes: state.notes.map(function (note) {
                    if (note.noteId === action.noteId) {
                        return updateObject(note, { editMode: action.mode });
                    }
                    else {
                        return note;
                    }
                })
            });
        }
        case RENAME_NOTE: {
            return updateObject(state, {
                notes: state.notes.map(function (note) {
                    if (note.noteId === action.noteId) {
                        return updateObject(note, { title: action.newName });
                    }
                    else {
                        return note;
                    }
                })
            });
        }
        case UPDATE_NOTE_FOLDER: {
            return updateObject(state, {
                noteFolder: action.noteFolder
            });
        }
        case ADD_NOTE: {
            var existNoteIndex = state.notes.findIndex(function (note) { return note.noteId === action.note.noteId; });
            if (existNoteIndex >= 0) {
                var newNotes = __spreadArray([], __read(state.notes), false);
                newNotes[existNoteIndex] = action.note;
                return updateObject(state, {
                    notes: newNotes
                });
            }
            return updateObject(state, {
                notes: __spreadArray([action.note], __read(state.notes), false)
            });
        }
        case UPDATE_LOCAL_NOTE_CONTENT: {
            return updateObject(state, {
                noteFolder: updateContentPreview(state.noteFolder, action.content.substr(0, CONTENT_PREVIEW_LENGTH), action.updateTime, action.noteId),
                notes: state.notes.map(function (note) {
                    if (note.noteId === action.noteId) {
                        return updateObject(note, {
                            content: action.content,
                            cursorPosition: action.cursorPosition,
                            lastEditSession: action.lastEditSession
                        });
                    }
                    else {
                        return note;
                    }
                })
            });
        }
        case DELETE_NOTE: {
            return updateObject(state, {
                notes: state.notes.filter(function (note) { return note.noteId !== action.noteId; })
            });
        }
        default:
            return state;
    }
}
function updateNote(notes, noteId, updateAction) {
    return produce(notes, function (draftState) {
        var noteData = draftState.find(function (note) { return note.noteId === noteId; });
        if (noteData) {
            updateAction(noteData);
        }
    });
}
function updateLastUpdateTime(noteFolder, noteId, updateTime) {
    return updateField(noteFolder, noteId, { lastUpdateTime: updateTime });
}
function setPin(noteFolder, fileId, isPinned) {
    return updateField(noteFolder, fileId, { pinned: isPinned });
}
