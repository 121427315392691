var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { MarkdownEditMode } from "./MarkdownEditMode";
var LanguageMode = /** @class */ (function () {
    function LanguageMode(name, mime, markdownCodeBlockName, defaultEditMode) {
        this.name = name;
        this.mime = mime;
        this.markdownCodeBlockName = markdownCodeBlockName;
        this.defaultEditMode = defaultEditMode;
    }
    LanguageMode.getModeFromMime = function (mime) {
        var _a;
        return ((_a = LanguageMode.allModes.find(function (mode) { return mode.mime === mime; })) !== null && _a !== void 0 ? _a : LanguageMode.PLAIN_TEXT);
    };
    // Treat as note if it is not diagram to fix the bug that if user rename
    // a file with unknown extension, it will disappear, should fix it before
    // introduce new type document
    LanguageMode.isNote = function (mime) {
        return !LanguageMode.isDiagram(mime);
    };
    LanguageMode.isDiagram = function (mime) {
        return LanguageMode.getDiagramMimeSet().has(mime);
    };
    LanguageMode.prototype.isDiagram = function () {
        return LanguageMode.isDiagram(this.mime);
    };
    LanguageMode.PLAIN_TEXT = new LanguageMode("Plain Text", "text/plain", "", MarkdownEditMode.CODE);
    LanguageMode.MARKDOWN = new LanguageMode("Markdown", "text/x-markdown", "markdown", MarkdownEditMode.RICH_TEXT);
    LanguageMode.HTML = new LanguageMode("HTML", "text/html", "html", MarkdownEditMode.CODE);
    LanguageMode.SQL = new LanguageMode("SQL", "text/x-sql", "sql", MarkdownEditMode.CODE);
    LanguageMode.JAVASCRIPT = new LanguageMode("JavaScript", "text/javascript", "javascript", MarkdownEditMode.CODE);
    LanguageMode.TYPESCRIPT = new LanguageMode("TypeScript", "application/typescript", "typescript", MarkdownEditMode.CODE);
    LanguageMode.JAVA = new LanguageMode("Java", "text/x-java", "java", MarkdownEditMode.CODE);
    LanguageMode.JSON = new LanguageMode("JSON", "application/json", "json", MarkdownEditMode.CODE);
    LanguageMode.XML = new LanguageMode("XML", "application/xml", "xml", MarkdownEditMode.CODE);
    LanguageMode.KOTLIN = new LanguageMode("Kotlin", "text/x-kotlin", "kotlin", MarkdownEditMode.CODE);
    LanguageMode.C = new LanguageMode("C", "text/x-csrc", "c", MarkdownEditMode.CODE);
    LanguageMode.CPP = new LanguageMode("C++", "text/x-c++src", "cpp", MarkdownEditMode.CODE);
    LanguageMode.CSHARP = new LanguageMode("C#", "text/x-csharp", "csharp", MarkdownEditMode.CODE);
    LanguageMode.OBJECTIVE_C = new LanguageMode("Objective C", "text/x-objectivec", "objectivec", MarkdownEditMode.CODE);
    LanguageMode.SWIFT = new LanguageMode("Swift", "text/x-swift", "swift", MarkdownEditMode.CODE);
    LanguageMode.DART = new LanguageMode("Dart", "application/dart", "dart", MarkdownEditMode.CODE);
    LanguageMode.DIFF = new LanguageMode("Diff", "text/x-diff", "diff", MarkdownEditMode.CODE);
    LanguageMode.GO = new LanguageMode("GO", "text/x-go", "go", MarkdownEditMode.CODE);
    LanguageMode.Python = new LanguageMode("Python", "text/x-python", "python", MarkdownEditMode.CODE);
    LanguageMode.PHP = new LanguageMode("PHP", "text/x-php", "php", MarkdownEditMode.CODE);
    LanguageMode.PERL = new LanguageMode("Perl", "text/x-perl", "perl", MarkdownEditMode.CODE);
    LanguageMode.RUBY = new LanguageMode("Ruby", "text/x-ruby", "ruby", MarkdownEditMode.CODE);
    LanguageMode.RUST = new LanguageMode("Rust", "text/x-rustsrc", "rust", MarkdownEditMode.CODE);
    LanguageMode.ELM = new LanguageMode("Elm", "text/x-elm", "elm", MarkdownEditMode.CODE);
    LanguageMode.ERLANG = new LanguageMode("Erlang", "text/x-erlang", "erlang", MarkdownEditMode.CODE);
    LanguageMode.OCAML = new LanguageMode("OCaml", "text/x-ocaml", "ocaml", MarkdownEditMode.CODE);
    LanguageMode.MERMAID = new LanguageMode("Mermaid", "text/x-mermaid", "mermaid", MarkdownEditMode.SPLIT);
    LanguageMode.noteModes = [
        LanguageMode.PLAIN_TEXT,
        LanguageMode.MARKDOWN,
        LanguageMode.HTML,
        LanguageMode.SQL,
        LanguageMode.JAVASCRIPT,
        LanguageMode.TYPESCRIPT,
        LanguageMode.JAVA,
        LanguageMode.JSON,
        LanguageMode.XML,
        LanguageMode.KOTLIN,
        LanguageMode.C,
        LanguageMode.CPP,
        LanguageMode.CSHARP,
        LanguageMode.OBJECTIVE_C,
        LanguageMode.SWIFT,
        LanguageMode.DART,
        LanguageMode.DIFF,
        LanguageMode.GO,
        LanguageMode.Python,
        LanguageMode.PHP,
        LanguageMode.PERL,
        LanguageMode.RUBY,
        LanguageMode.RUST,
        LanguageMode.ELM,
        LanguageMode.ERLANG,
        LanguageMode.OCAML
    ];
    LanguageMode.diagramModes = [LanguageMode.MERMAID];
    LanguageMode.allModes = __spreadArray(__spreadArray([], __read(LanguageMode.noteModes), false), __read(LanguageMode.diagramModes), false);
    LanguageMode.noteMimeSet = new Set();
    LanguageMode.diagramMimeSet = new Set();
    LanguageMode.getNoteMimeSet = function () {
        return LanguageMode.getMimeSet(LanguageMode.noteModes, LanguageMode.noteMimeSet);
    };
    LanguageMode.getDiagramMimeSet = function () {
        return LanguageMode.getMimeSet(LanguageMode.diagramModes, LanguageMode.diagramMimeSet);
    };
    LanguageMode.getMimeSet = function (modes, set) {
        if (set.size === 0) {
            modes.forEach(function (mode) {
                set.add(mode.mime);
            });
        }
        return set;
    };
    return LanguageMode;
}());
export { LanguageMode };
