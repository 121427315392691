var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import produce from "immer";
import uuid from "uuid-random";
import { LanguageMode } from "../../model/LanguageMode";
import { getContentPreview } from "../../model/NoteData";
import FileSorter from "../fileexplorer/FileSorter";
export var CONTENT_PREVIEW_LENGTH = 176;
export var DEFAULT_CONTENT_PREVIEW_ROW = 1;
export function fromNoteData(noteData, isPinned) {
    return {
        id: noteData.noteId,
        name: noteData.title,
        toggled: false,
        children: null,
        contentPreview: getContentPreview(noteData, CONTENT_PREVIEW_LENGTH),
        contentPreviewRow: DEFAULT_CONTENT_PREVIEW_ROW,
        lastUpdateTime: noteData.lastUpdateTime,
        pinned: isPinned,
        mime: noteData.mime
    };
}
/**
 * Return all files in this folder, included all files in sub folders.
 */
export function getNestedChildFiles(rootFolder) {
    var e_1, _a;
    var files = [];
    if (rootFolder.children === null) {
        return files;
    }
    try {
        for (var _b = __values(rootFolder.children), _c = _b.next(); !_c.done; _c = _b.next()) {
            var child = _c.value;
            if (isFolder(child)) {
                files.push.apply(files, __spreadArray([], __read(getNestedChildFiles(child)), false));
            }
            else {
                files.push(child);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return files;
}
/**
 * Return root folder structure that include all folders in child, files are
 * filter out.
 */
export function getChildFolderOnly(rootFolder) {
    if (rootFolder.children === null) {
        return rootFolder;
    }
    var childFolderOnly = updateField(rootFolder, rootFolder.id, {
        children: rootFolder.children.reduce(function (result, current) {
            if (isFolder(current)) {
                result.push(getChildFolderOnly(current));
            }
            return result;
        }, [])
    });
    return childFolderOnly;
}
export function addFolder(rootFolder, parentFolderId, folderName) {
    return addFile(rootFolder, parentFolderId, {
        id: uuid(),
        name: folderName,
        toggled: false,
        children: [],
        contentPreview: "",
        contentPreviewRow: DEFAULT_CONTENT_PREVIEW_ROW,
        lastUpdateTime: new Date().getTime(),
        pinned: false,
        mime: ""
    });
}
export function addFile(rootFolder, parentFolderId, toAddFile) {
    return deprecatedUpdateField(rootFolder, parentFolderId, function (file) {
        if (file.children != null) {
            file.children = FileSorter.sortByFileName(__spreadArray(__spreadArray([], __read(file.children), false), [toAddFile], false));
        }
    });
}
export function removeFile(rootFolder, targetFileId) {
    var parentOfTarget = findParentFolder(rootFolder, targetFileId);
    if (!parentOfTarget) {
        return rootFolder;
    }
    return deprecatedUpdateField(rootFolder, parentOfTarget.id, function (file) {
        if (file.children != null) {
            file.children = file.children.filter(function (child) { return child.id !== targetFileId; });
        }
    });
}
export function findParentFolder(root, id) {
    var e_2, _a;
    if (id === root.id) {
        return null;
    }
    if (!root.children) {
        return null;
    }
    try {
        for (var _b = __values(root.children), _c = _b.next(); !_c.done; _c = _b.next()) {
            var child = _c.value;
            if (id === child.id) {
                return root;
            }
            if (isFolder(child)) {
                var result = findParentFolder(child, id);
                if (result) {
                    return result;
                }
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return null;
}
export function getAllParentFolderIdsContainFile(root, targetFileId) {
    var parent = findParentFolder(root, targetFileId);
    if (parent === null) {
        return [];
    }
    if (parent.id === root.id) {
        return [parent.id];
    }
    return __spreadArray(__spreadArray([], __read(getAllParentFolderIdsContainFile(root, parent.id)), false), [parent.id], false);
}
export function find(root, id) {
    var e_3, _a;
    if (root.id === id) {
        return root;
    }
    if (!root.children) {
        return null;
    }
    try {
        for (var _b = __values(root.children), _c = _b.next(); !_c.done; _c = _b.next()) {
            var child = _c.value;
            if (id === child.id) {
                return child;
            }
            if (isFolder(child)) {
                var result = find(child, id);
                if (result) {
                    return result;
                }
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return null;
}
export function updateField(rootFolder, fileId, partialChange) {
    return deprecatedUpdateField(rootFolder, fileId, function (file) {
        Object.assign(file, partialChange);
    });
}
export function updateContentPreview(rootFolder, contentPreview, updateTime, fileId) {
    return deprecatedUpdateField(rootFolder, fileId, function (file) {
        file.contentPreview = contentPreview;
        file.lastUpdateTime = updateTime;
    });
}
export function updateContentPreviewRow(rootFolder, row, fileId) {
    return deprecatedUpdateField(rootFolder, fileId, function (file) {
        file.contentPreviewRow = row;
    });
}
export function rename(rootFolder, newName, fileId) {
    var parentFolder = findParentFolder(rootFolder, fileId);
    if (!parentFolder) {
        return rootFolder;
    }
    var renamedRoot = deprecatedUpdateField(rootFolder, fileId, function (file) {
        file.name = newName;
    });
    return deprecatedUpdateField(renamedRoot, parentFolder.id, function (parent) {
        if (parent.children) {
            parent.children = FileSorter.sortByFileName(parent.children);
        }
    });
}
export function toggleFolderOpenState(rootFolder, fileId) {
    return deprecatedUpdateField(rootFolder, fileId, function (file) {
        file.toggled = !file.toggled;
    });
}
export function updateIsFolderOpen(rootFolder, isOpen, fileId) {
    return deprecatedUpdateField(rootFolder, fileId, function (file) {
        file.toggled = isOpen;
    });
}
function deprecatedUpdateField(rootFolder, fileId, updateAction) {
    return produce(rootFolder, function (draftState) {
        var targetFile = findFile(draftState, fileId);
        if (targetFile) {
            updateAction(targetFile);
        }
    });
}
export function isFolder(file) {
    return file.children !== null;
}
export function findFile(rootFolder, id) {
    var e_4, _a;
    if (id === rootFolder.id) {
        return rootFolder;
    }
    if (!rootFolder.children) {
        return null;
    }
    try {
        for (var _b = __values(rootFolder.children), _c = _b.next(); !_c.done; _c = _b.next()) {
            var child = _c.value;
            if (id === child.id) {
                return child;
            }
            if (isFolder(child)) {
                var result = findFile(child, id);
                if (result) {
                    return result;
                }
            }
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return null;
}
export function getFileType(mime) {
    if (LanguageMode.isDiagram(mime)) {
        return FileType.DIAGRAM;
    }
    else {
        return FileType.NOTE;
    }
}
export function isNote(file) {
    return getFileType(file.mime) === FileType.NOTE;
}
export function isDiagram(file) {
    return getFileType(file.mime) === FileType.DIAGRAM;
}
export var FileType;
(function (FileType) {
    FileType[FileType["NOTE"] = 0] = "NOTE";
    FileType[FileType["DIAGRAM"] = 1] = "DIAGRAM";
})(FileType || (FileType = {}));
