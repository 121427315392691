export var SHOW_CONFIRM_DELETE_FILE_DIALOG = "SHOW_CONFIRM_DELETE_FILE_DIALOG";
export var DISMISS_CONFIRM_DELETE_FILE_DIALOG = "DISMISS_CONFIRM_DELETE_FILE_DIALOG";
export var SHOW_RENAME_FILE_DIALOG = "SHOW_RENAME_FILE_DIALOG";
export var DISMISS_RENAME_FILE_DIALOG = "DISMISS_RENAME_FILE_DIALOG";
export var SHOW_LOGIN_DIALOG = "SHOW_LOGIN_DIALOG";
export var DISMISS_LOGIN_DIALOG = "DISMISS_LOGIN_DIALOG";
export var SHOW_LOGOUT_DIALOG = "SHOW_LOGOUT_DIALOG";
export var DISMISS_LOGOUT_DIALOG = "DISMISS_LOGOUT_DIALOG";
export var INITIALIZING_APP = "INITIALIZING_APP";
export var SHOW_RIGHT_CLICK_MENU = "SHOW_RIGHT_CLICK_MENU";
export var DISMISS_RIGHT_CLICK_MENU = "DISMISS_RIGHT_CLICK_MENU";
export var SHOW_NEW_FILE_DIALOG = "SHOW_NEW_FILE_DIALOG";
export var DISMISS_NEW_FILE_DIALOG = "DISMISS_NEW_FILE_DIALOG";
export var CHANGE_FILE_NAVIGATION_MODE = "CHANGE_FILE_NAVIGATION_MODE";
export var SET_EDITING_NOTE = "SET_EDITING_NOTE";
export var SET_EDITING_DIAGRAM = "SET_EDITING_DIAGRAM";
export var SET_EDITING_FILE = "SET_EDITING_FILE";
export var UPDATE_NETWORK_STATUS = "UPDATE_NETWORK_STATUS";
export var SHOW_FORMAT_ERROR_DIALOG = "SHOW_FORMAT_ERROR_DIALOG";
export var DISMISS_FORMAT_ERROR_DIALOG = "DISMISS_FORMAT_ERROR_DIALOG";
export var REQUEST_FORMAT_CODE = "REQUEST_FORMAT_CODE";
export var SHOW_ALERT_MESSAGE = "SHOW_ALERT_MESSAGE";
export var DISMISS_ALERT_MESSAGE = "DISMISS_ALERT_MESSAGE";
export var SHOW_SETTINGS_DIALOG = "SHOW_SETTINGS_DIALOG";
export var DISMISS_SETTINGS_DIALOG = "DISMISS_SETTINGS_DIALOG";
export var SET_DISTRACTION_FREE_VIEW = "SET_DISTRACTION_FREE_VIEW";
export var SET_THEME = "SET_THEME";
export var SHOW_MESSAGE_DIALOG = "SHOW_MESSAGE_DIALOG";
export var DISMISS_MESSAGE_DIALOG = "DISMISS_MESSAGE_DIALOG";
export var RESIZE_NOTE_ITEM_HEIGHT = "RESIZE_NOTE_ITEM_HEIGHT";
export var SHOW_MOVE_TO_FOLDER_DIALOG = "SHOW_MOVE_TO_FOLDER_DIALOG";
export var DISMISS_MOVE_TO_FOLDER_DIALOG = "DISMISS_MOVE_TO_FOLDER_DIALOG";
